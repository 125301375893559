<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
  .c-force-height {
    min-height: 50vh !important;
  }
}
.event-details-icon {
  cursor: pointer;
}
.event-details {
  display: none;
  -webkit-transition: all .4s;
  transition: all .4s;
}
.expanded-details .event-details {
  display: block;
}
</style>
<script>
import { VclList } from 'vue-content-loading';
import Multiselect from "vue-multiselect";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id', 'options'],
  components: {
    VclList,
    Multiselect,
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server.id}/serverLogsQuery`);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy,
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10)
            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            this.$toast.error('Failed to contact query service');
            this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      this.currentPage = 1;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    },
    toList(obj) {
      let results = [];
      let ignoredParameters = ['string', 'kick_reason'];
      Object.keys(obj).forEach((k) => {
        if(ignoredParameters.includes(k)) return;
        let v = obj[k];
        let s = null;
        switch(k) {
          case 'seconds': {
            k = 'duration';
            s = 'timeago';
            break;
          }
          default:
        }
        results.push({'k': k, 'v': v, 's': s});
      });
      return results;
    },
    async expandDetails(event) {
      if(event.target.parentElement.classList.contains('expanded-details'))
        event.target.parentElement.classList.remove('expanded-details')
      else event.target.parentElement.classList.add('expanded-details')
    },
    hasItems(map) {
      return Object.keys(map).length
    },
    getJSDate(v) {
      return new Date(Date.now() - 1000 * v * 60);
    },
    // https://github.com/kazupon/vue-i18n/issues/584
    flattenObjectParameters(obj, prefix = '') {
      return obj;
    },
    actionTranslation(event) {
      event.parameters.player.position = event.position.coordinates;
      event.parameters.position = event.position.coordinates;
      event.parameters.action_string = this.$t(`gameactions.${event.parameters.action}`);
      if(event === 'player.interact') {
        if(event.parameters.action === 'ActionRaiseFlag') delete event.parameters.item;
        if(event.parameters.item) {
          if(!event.parameters.target) {
            event.parameters.target = event.parameters.item;
            return `${this.$t(`events.player.interact_woitem`, event.parameters)}`;
          } else return `${this.$t(`events.player.interact`, event.parameters)}`;
        } else {
          return this.$t(`events.player.interact_woitem`, event.parameters);
        }
      }
      return this.$t(`events.${event.event}`, event.parameters);
    },
    specialTranslation(event) {
      switch(event.event) {
        case 'player.place': {
          event.parameters.player.position = event.position.coordinates;
          event.parameters.position = event.position.coordinates;
          return `${this.$t(`events.player.place`, event.parameters)}`;
        }
        case 'player.death': {
          if(event.parameters.murderer) {
            return `${event.parameters.player.name} ${this.$t('events.player.killed')} ${event.parameters.murderer.name} (${event.parameters.weapon}, ${event.parameters.distance}m)`;
          } else {
            return `${event.parameters.player.name} ${this.$t('events.player.death')}`;
          }
        }
        case 'player.damage': {
          return `${event.parameters.player.name} ${this.$t('events.player.damaged')} ${event.parameters.murderer.name} (${event.parameters.zone}, ${event.parameters.weapon}, ${event.parameters.distance}m, ${event.parameters.damage} damage)`;
        }
        case 'player.envdeath': {
          if(event.parameters.cause === '__environment') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__infected') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_infected')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__bleedout') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_bleedout')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__starvation') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_starvation')} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__animal') {
            return `${this.$t('events.player.death_animal', {animal: event.parameters.object})} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__object') {
            return `${this.$t('events.player.death_object', {object: event.parameters.object})} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__explosion') {
            return `${this.$t('events.player.death_explosion')} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__fall_damage') {
            return `${this.$t('events.player.death_falldamage')} (${event.parameters.player.position})`;
          }
          return `${event.parameters.player.name} ${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
        }
        case 'player.suicide': {
          return `${event.parameters.player.name} ${this.$t('events.player.suicide')} (${event.parameters.player.position})`;
        }
        default: return event.event;
      }
    },
    translatePosition(pos) {
      // Statics for lat lang transformation
      let units = 90.0, map_size = 30720.0;

      let map_x = pos.coordinates[0];
      let map_y = pos.coordinates[1];

      let x = (map_x) / (units / map_size);
      let y = (((-1*units) - map_y) / (units / map_size)) * -1;
      return `${Number((x).toFixed(2))}, ${Number((y).toFixed(2))}`;
    },
    onServerChange() {
      if(this.server === null) return;
      this.fetchData();
    }
  },
  created() {
    /*
    <div
                    class="profile-server-link text-muted m-1"
                    :class="{'profile-link-active': (currentTab === 'ProfileServer' && options.server_id === server.id)}"
                    v-on:click="changeTab('ProfileServer', {server_id: server.id})"
                    v-for="server in structure.servers" :key="server.id"
                >
                  <img :src="'/' +gameIcon(server.game)" alt="server game logo" class="logo-img" height="16px" width="16px">
                  {{ server.identifier }}
                  <span class="badge badge-primary" v-if="server.dataset_available">
                    <i class="fas fa-user" />
                  </span>
                </div>
     */
    this.options.structure.servers.forEach((server) => {
      this.availableServers.push({
        id: server.id,
        game: server.game,
        title: server.identifier
      });
    });
    this.query.search_term = this.cftools_id;
  },
  mounted() {
    this.ready = true;
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  data() {
    return {
      ready: false,
      error: false,
      server: null,
      availableServers: [],
      translatedEvents: [
        'rcon.connection_established',
        'rcon.downtime_cleared',
        'rcon.authentication_error',
        'user.kicked',
        'user.command',
        'user.join',
        'user.leave',
        'user.chat',
      ],
      actionEvents: [
        'player.interact',
      ],
      specialEvents: [
        'player.death',
        'player.damage',
        'player.envdeath',
        'player.suicide',
        'player.place',
      ],
      detailEvents: [
        'player.interact',
        'rcon.downtime_cleared', // Display downtime
        'rcon.authentication_error', // Display downtime
        'player.death',
        'player.envdeath',
        'player.suicide',
        'player.interact',
        'player.damage'
      ],
      blockedDetailKeys: [
        'cause',
        'queryables',
        'player',
        'murderer',
        'action_string'
      ],
      // Table
      items: [],
      refId: 0,
      fields: [
        {
          key: 'date',
          label: this.$t('server.view.protocol.columns.date'),
          sortable: true
        },
        {
          key: 'event',
          label: this.$t('server.view.protocol.columns.action'),
          sortable: true
        },
        {
          key: 'target',
          label: this.$t('server.view.protocol.columns.target'),
          sortable: true
        },
        {
          key: 'position',
          label: this.$t('server.view.protocol.columns.position'),
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "date",
      sortDesc: true,
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: true,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card card-body c-force-height">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <multiselect
                    v-model="server"
                    label="id"
                    track-by="id"
                    :options="availableServers"
                    :show-labels="false"
                    :allow-empty="true"
                    @input="onServerChange()"
                >
                  <template
                      slot="singleLabel"
                      slot-scope="props"
                  >
                    <div>
                      <img
                          class="option__image"
                          :src="'/' +gameIcon(props.option.game)"
                      >
                      <span class="option__desc d-inline ml-2">
                            <span class="option__title align-middle h4">
                              {{ props.option.title }}
                            </span>
                          </span>
                    </div>
                  </template>
                  <template
                      slot="option"
                      slot-scope="props"
                  >
                    <div>
                      <img class="option__image d-inline-block" :src="'/' +gameIcon(props.option.game)">
                      <div class="option__desc d-inline ml-2">
                        <span class="option__title align-middle h4">
                          {{ props.option.title }}
                        </span>
                        <span class="option__small align-middle ml-2 h6">
                          {{ props.option.id }}
                        </span>
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="row mt-2" v-if="server">
              <div class="col-lg-12 col-sm-12">
                <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
                  <div  class="info-component text-center align-middle mt-auto mb-auto">
                    <div class="info-component-space">
                      <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
                    </div>
                  </div>
                </div>
                <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                  <template #empty="scope">
                    <h4>{{ $t('components.table.filters.empty') }}</h4>
                  </template>
                  <template #cell(date)="data">
                    {{ $d(parseDate(data.item.date), 'datetime', getDTLocale()) }}
                  </template>
                  <template #cell(position)="data">
                    <template v-if="data.item.position">
                      <h5 class="text-uppercase">
                        {{ translatePosition(data.item.position) }}
                      </h5>
                    </template>
                  </template>
                  <template #cell(target)="data">
                    <template v-if="data.item.murderer.cftools_id">
                      <router-link :to="{name: 'profile', params: {cftools_id: data.item.murderer.cftools_id}}" v-if="data.item.murderer.display_name">
                        <img
                            :src="data.item.murderer.avatar"
                            alt="profile picture"
                            class="rounded-circle avatar-xs"
                        >
                        {{ data.item.murderer.display_name }}
                      </router-link>
                      <router-link :to="{name: 'profile', params: {cftools_id: data.item.murderer.cftools_id}}" v-else>
                        {{ data.item.murderer.name }}
                      </router-link>
                    </template>
                    <template v-else>
                      <!-- Empty -->
                    </template>
                  </template>
                  <template #cell(event)="data">
                    <b class="text-code font-size-14 text-white" :class="{'event-details-icon': hasItems(data.item.parameters)}" v-on:click="expandDetails($event)">
                      <template v-if="translatedEvents.includes(data.item.event)">
                        {{ $t(`events.${data.item.event}`, data.item.parameters) }}
                      </template>
                      <template v-else-if="actionEvents.includes(data.item.event)">
                        {{ actionTranslation(data.item) }}
                      </template>
                      <template v-else-if="specialEvents.includes(data.item.event)">
                        {{ specialTranslation(data.item) }}
                      </template>
                      <template v-else>
                        <span class="text-lowercase ">{{ data.item.event }}</span>
                      </template>
                    </b>
                    <template v-if="detailEvents.includes(data.item.event)">
                      <i class="fad fa-info-circle font-size-16 event-details-icon" v-if="Object.keys(data.item.parameters).length" v-on:click="expandDetails($event)"/>
                      <div class="event-details" v-if="Object.keys(data.item.parameters).length">
                        <div v-for="parameter in toList(data.item.parameters)" :key="parameter.k">
                          <template v-if="!blockedDetailKeys.includes(parameter.k)">
                            <template v-if="parameter.s === 'timeago'">
                              <b class="text-code">{{ parameter.k }}: </b>
                              <span class="text-code">
                        <time-ago :refresh="0" :datetime="getJSDate(parameter.v)" :locale="getUILanguage()"></time-ago>
                      </span>
                            </template>
                            <template v-else>
                              <b class="text-code">{{ parameter.k }}:</b> <span class="text-code">{{ parameter.v }}</span>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </b-table>
                <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="card">
                <div class="card-body text-center">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" width="400px"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
